import React, { useState, useRef, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../data/firebase";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';


import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function Quote() {

    const { uid, qid } = useParams();
    const [error, setError] = useState(null);
    const [uploadDoc, setUploadDoc] = useState(null);
    const [isUploading, setIsUploading] = useState(true);
    const [quoteData, setQuoteData] = useState(null);

    const getData = async () => {
        const exactUid = uid.split('').reverse().join('');
        try {
            const quoteDocRef = doc(db, 'quotes', exactUid);
            const quoteDoc = await getDoc(quoteDocRef);
            const quoteDocData = quoteDoc.data();
            setQuoteData(quoteDocData.quotes[qid]);
        } catch (err) {
            setError(err);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className='admin'>

            <main>
                <Helmet>
                    <title>Quotes</title>
                </Helmet>
                <div style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 'fit-content', alignContent: 'center',
                    justifyContent: 'space-evenly', alignItems: 'center', margin: '10px 0px'
                }}>
                    {error && <Alert severity="error">{error.message}</Alert>}
                    <QuoteCard quoteData={quoteData} />
                </div>
            </main>
        </div>
    );
}
const QuoteCard = ({ quoteData }) => {
    if (!quoteData) return null;
    const quote = quoteData.quote[quoteData.quote.length - 1];
    const themes = quoteData.themes;
    const likes = quoteData.likes;
    const formattedText = (text) => {
        let formattedText = text;
        let textArray = text.split('\n');
        const newTextArray = [];
        for (let i = 0; i < textArray.length; i++) {
            let textStr = textArray[i];

            var replaced;
            if (textStr.startsWith('- ')) {
                replaced = '<ul><li>' + textStr.replace('- ', '') + '</li>';
                while (textArray[i + 1]?.startsWith('- ')) {
                    i++;
                    replaced += '<li>' + textArray[i].replace('- ', '') + '</li>';
                }
                replaced += '</ul>';
            } else if (textStr.startsWith('> ')) {
                replaced = '"' + textStr.replace('> ', '') + '"';
            } else {
                replaced = textStr;
            }
            replaced = replaced.replace(/_([^_]+)_/g, '<i>$1</i>');
            replaced = replaced.replace(/\*([^*]+)\*/g, '<b>$1</b>');
            replaced = replaced.replace(/~([^~]+)~/g, '<s>$1</s>');
            replaced = replaced.replace(/`([^`]+)`/g, '<code>$1</code>');
            newTextArray.push(replaced);
        }
        formattedText = newTextArray.join('<br />');
        return <div style={{ textAlign: 'left' }} key={Math.random()} dangerouslySetInnerHTML={{ __html: formattedText }} />;
    };

    const handleShare = async () => {
        try {
            let url = window.location.href;
            if (navigator.share) {
                await navigator.share({
                    title: 'RPKR.IN Quotes',
                    text: 'Check out this quotes!',
                    url: url,
                });
            } else {
                navigator.clipboard.writeText(url);
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };
    return (
        <Card sx={{ width: '90vw', maxWidth: '350px', padding: '5px' }}>
            <CardContent>
                {formattedText(quote)}
                <br />
                {themes.map((theme, index) => (
                    <Chip sx={{
                        margin: 1,
                    }} label={theme} key={index} />
                ))}
                <br />
                <div>
                    <Tooltip title="User no of impressions">
                        <Button
                            sx={{
                                borderRadius: 6,
                            }} color="error">
                            <FavoriteIcon />
                            <span>{likes}</span>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Share">
                        <IconButton
                            onClick={handleShare}
                        >
                            <ShareIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </CardContent>
        </Card>
    )
}
