import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LinkIcon from '@mui/icons-material/Link';
import PsychologyIcon from '@mui/icons-material/Psychology';

export default function Nav(props) {
  const [value, setValue] = useState('admin');
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    if (path[0] === '/') path = path.slice(1);
    if (path[path.length - 1] === '/') path = path.slice(0, -1);
    setValue(path);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!props.user) return null;
  return (
    <BottomNavigation
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        backgroundColor: '#111',
      }}
      value={value} onChange={handleChange}
    >
      <BottomNavigationAction
        component={Link}
        to="/admin"
        label="Account"
        value="admin"
        icon={<AccountCircleIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/admin/quote"
        label="Quotes"
        value="admin/quote"
        icon={<PsychologyIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/admin/short"
        label="Short Links"
        value="admin/short"
        icon={<LinkIcon />}
      />
    </BottomNavigation>
  );
}
