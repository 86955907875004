// App.js
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Portfolio from './pages/portfolio';
import Admin from './pages/admin';
import Quote from './pages/quote';


import { Provider } from 'react-redux';
import store from './data/store';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Routes>
          <Route exact path="/" element={<Portfolio />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="/:shortName" element={<Portfolio />} />
          <Route path="/quote/:uid/:qid" element={<Quote />} />
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;