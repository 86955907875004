import { useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../data/firebase";
import Nav from '../component/nav';
import AccountPage from './adminPages/account-page';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { useShortLinkUpdateFirebase, useQuoteUpdateFirebase } from "../data/firebaseHooks";

import NotFoundPage from './adminPages/not-found-page';
import ShortPage from './adminPages/short-page';
import QuotePage from './adminPages/quote-page';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../data/store';

function Check({ bAuth, element }) {
    const user = useSelector((state) => state.user) !== null ? true : false;
    return (user ? element : <Navigate to="/admin" replace />);
}

export default function Admin() {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const shortLink = useSelector((state) => state.shortLink);
    const quotes = useSelector((state) => state.quotes);

    const [uploadSLData, isUploadingSL, uploadErrorSL] = useShortLinkUpdateFirebase();
    const [uploadQData, isUploadingQ, uploadErrorQ] = useQuoteUpdateFirebase();
    const handleSLFireStoreTimeOut = (uid) => {
        uploadSLData(uid);
    };
    const handleQFireStoreTimeOut = (uid) => {
        uploadQData(uid);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const unsubscribeAuth = onAuthStateChanged(auth, async (users) => {
                    if (users) {
                        const userData = {
                            uid: users.uid,
                            email: users.email,
                            displayName: users.displayName,
                            lastLoginAt: users.metadata.lastLoginAt,
                            lastRefereshAt: users.reloadUserInfo.lastRefreshAt
                        }
                        dispatch(setUser(userData));
                        // handleSLFireStoreTimeOut(userData.uid);
                        // handleQFireStoreTimeOut(userData.uid);
                    } else {
                        dispatch(setUser(null));
                    }
                });

                return () => {
                    unsubscribeAuth();
                };
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };

        fetchData();
    }, [auth]);
    useEffect(() => {
        if (user?.uid) {
            const timeNow = new Date().getTime();
            if (!(quotes[user.uid]?.fireStoreTime) || (quotes[user.uid]?.fireStoreTime && ((timeNow - quotes[user.uid]?.fireStoreTime)) > 3600000)) {
                handleQFireStoreTimeOut(user.uid);
            }
        }
    }, [quotes]);
    useEffect(() => {
        if (user?.uid) {
        const timeNow = new Date().getTime();
            if (!(shortLink.fireStoreTime) || (shortLink.fireStoreTime && ((timeNow - shortLink.fireStoreTime)) > 3600000)) {
                handleSLFireStoreTimeOut();
            }
        }
    }, [shortLink]);
    return (
        <div className='admin'>
            <Routes>
                <Route exact path="/" element={<AccountPage user={user} />} />
                <Route path="/short" element={<Check element={<ShortPage user={user} />} />} />
                <Route path="/quote" element={<Check element={<QuotePage user={user} />} />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Nav user={user} />
        </div>
    );
}
