//short-page.js
import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addShortLink, editShortLink, deleteShortLink, restoreShortLink } from '../../data/store';
import { useShortLinkUpdateFirebase } from "../../data/firebaseHooks";

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import RestoreIcon from '@mui/icons-material/Restore';
import ShareIcon from '@mui/icons-material/Share';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
/*
Custom URL Shortener & Count Iterator:
    - Create shortname for links and enable redirection.
    - Allow user to edit and URL link & deadline but not the keyword.
    - Track and provide analytics on referral sources.
    - Enable password protection for specific short URLs.
    - Implement QR code generation for short URLs.

    Components:
    shortName: Input required
    Link: Input required
    Edit: Button 
    Delete: Button
    Referral: Input notRequired
    Password: Input notRequired
    QR Code: Button
    Copy: Button

    Also give a refresh button to add the shortLink data to the firestore database.
    
 */

const ShortLinkForm = ({ onClose }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const shortLinkDB = useSelector((state) => state.shortLink);
    const [shortName, setShortName] = useState('');
    const [link, setLink] = useState('');
    const [referral, setReferral] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = (e) => {
        //Add validation.
        e.preventDefault();
        setDisabled(true);
        let data = {
            shortName: shortName.trim().toLowerCase(),
            link: link.trim(),
            password: password.trim(),
            uid: user.uid
        }
        if (!link.match(/^(https?:\/\/)/)) {
            data.link = `https://${link}`.trim();
        }
        setShortName(shortName.trim().toLowerCase());
        setReferral(referral.trim().toLowerCase());
        if (shortLinkDB.arrayShortLinks.includes(shortName) || shortLinkDB.serverArrayLinks.includes(shortName)) {
            setErrorMessage('Short Name already exists');
            setDisabled(false);
            return false;
        } else if (shortName.trim() === '') {
            setErrorMessage('Short Name cannot be empty');
            setDisabled(false);
            return false;
        } else if (!shortName.match(/^[a-zA-Z0-9]+$/)) {
            setErrorMessage('Short Name can only be alphanumeric');
            setDisabled(false);
            return false;
        } else if (link.trim() === '') {
            setErrorMessage('Link cannot be empty');
            setDisabled(false);
            return false;
        } else if (!link.match(/^(https?:\/\/)?(www\.)?([A-Za-z\d.-]+)\.([A-Za-z]{2,6})(\/?[\w.-]*)*(#[\w-]*)?(\?[=&\w.-]*)*$/)) {
            setErrorMessage('Link is not valid');
            setDisabled(false);
            return false;
        }
        else if (referral.trim() !== '' && !referral.match(/^[a-zA-Z0-9]+$/)) {
            setErrorMessage('Referral can only be alphanumeric');
            setDisabled(false);
            return false;
        } else if (password.trim() !== '' && !password.match(/^[a-zA-Z0-9]+$/)) {
            setErrorMessage('Password can only be alphanumeric');
            setDisabled(false);
            return false;
        } else {
            setErrorMessage('');
        }
        dispatch(addShortLink(data));
        navigator.clipboard.writeText(`https://rpkr.in/#${shortName}`);
        setShortName('');
        setLink('');
        setPassword('');
        setDisabled(false);
        onClose();
    };

    return (
        <Card sx={{ minWidth: 275, width: 'fit-content', marginTop: '50px', maxWidth: '90vh', margin: '10px', padding: '5px' }}>
            <CardContent>
                <h2>Create Short Link</h2>

                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <TextField
                        margin="normal"
                        label="Short Name"
                        name="shortName"
                        required
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value.trim().toLowerCase())}
                        pattern="[a-zA-Z0-9]+"
                        autoComplete="off"
                    /> <TextField
                        margin="normal"
                        label="Link"
                        required
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        pattern="^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$"
                        autoComplete="off"
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <TextField
                        margin="normal"
                        label="Referral"
                        variant="outlined"
                        value={referral}
                        onChange={(e) => setReferral(e.target.value)}
                        pattern="[a-zA-Z0-9]+"
                        autoComplete="off"
                    />
                    <TextField
                        margin="normal"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                    />
                </div>
                <Button type="button" variant="contained" color="primary" onClick={handleSubmit} disabled={disabled}>
                    Create & Copy
                </Button>
            </CardContent>
        </Card>
    );
};

const ShortLinkList = () => {
    const user = useSelector((state) => state.user);
    const shortLinks = useSelector((state) => state.shortLink)[user.uid] || {};
    return (
        <div style={{ margin: '15px 0px', height: 'fit-content' }}>
            <>
                <div style={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 'fit-content', alignContent: 'center',
                    justifyContent: 'space-evenly', alignItems: 'center', margin: '10px 0px'
                }}>
                    {/* <ShortLinkForm /> */}
                    {
                        Object.keys(shortLinks).map((shortName, index) => (
                            <ShortCard cardName={shortName} key={index} index={index} />
                        ))}
                </div>
                {Object.keys(shortLinks).length < 1 && <h3>No Existing Short Links in your account</h3>}
            </>
        </div>
    );
};
const ShortCard = ({ cardName, index }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const shortLinkDB = useSelector((state) => state.shortLink);
    const shortLinks = shortLinkDB[user.uid] || {};
    const [editMode, setEditMode] = useState(false);
    const shortName = cardName;
    const [link, setLink] = useState(shortLinks[shortName]?.link || '');
    const [referral, setReferral] = useState('');
    const [password, setPassword] = useState(shortLinks[shortName]?.password || '');

    const [disabled, setDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        setDisabled(true);
        let data = {
            shortName: shortName.trim(),
            link: link.trim(),
            password: password.trim(),
            uid: user.uid
        }
        if (!link.match(/^(https?:\/\/)/)) {
            data.link = `https://${link}`.trim();
        }
        if (link.trim() === '') {
            setErrorMessage('Link cannot be empty');
            setDisabled(false);
            return false;
        } else if (!link.match(/^(https?:\/\/)?(www\.)?([A-Za-z\d.-]+)\.([A-Za-z]{2,6})(\/?[\w.-]*)*(#[\w-]*)?(\?[=&\w.-]*)*$/)) {
            setErrorMessage('Link is not valid');
            setDisabled(false);
            return false;
        }
        else if (referral.trim() !== '' && !referral.match(/^[a-zA-Z0-9]+$/)) {
            setErrorMessage('Referral can only be alphanumeric');
            setDisabled(false);
            return false;
        } else if (password.trim() !== '' && !password.match(/^[a-zA-Z0-9]+$/)) {
            setErrorMessage('Password can only be alphanumeric');
            setDisabled(false);
            return false;
        } else {
            setErrorMessage('');
        }
        e.preventDefault();
        dispatch(editShortLink(data));
        if (referral.trim() === '') {
            navigator.clipboard.writeText(`https://rpkr.in/#${shortName}`);
        }
        else {
            navigator.clipboard.writeText(`https://rpkr.in/#${shortName}?ref=${referral}`);
        }
        setEditMode(false);
        setDisabled(false);
    };
    const handleDelete = () => {
        dispatch(
            deleteShortLink({
                uid: user.uid,
                shortName,
            })
        );
    };
    const handleCopy = () => {
        if (referral.trim() === '') {
            navigator.clipboard.writeText(`https://rpkr.in/#${shortName}`);
        }
        else {
            navigator.clipboard.writeText(`https://rpkr.in/#${shortName}?ref=${referral}`);
        }
    };
    const handleShare = async () => {
        try {
            let url = '';
            if (referral.trim() === '') {
                url = (`https://rpkr.in/#${shortName}`);
            }
            else {
                url = (`https://rpkr.in/#${shortName}?ref=${referral}`);
            }
            if (navigator.share) {
                await navigator.share({
                    title: 'RPKR.IN Short Link',
                    text: 'Check out this URL!',
                    url: url,
                });
                console.log('Successfully shared');
            } else {
                throw new Error('Web Share API is not supported in this browser');
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    };
    const handleEdit = () => {
        setEditMode(true);
    };
    const handleRestore = () => {
        dispatch(
            restoreShortLink({
                uid: user.uid,
                shortName,
            })
        );
    };

    const handleCancelEdit = () => {
        setEditMode(false);
        setLink(shortLinks[shortName]?.link || '');
        setReferral('');
        setPassword(shortLinks[shortName]?.password || '');
    }
    return (
        <Card sx={{ minWidth: 275, width: 'fit-content', marginTop: '50px', maxWidth: '90vh', margin: '10px', padding: '5px' }}>
            <CardContent>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {shortLinks[shortName]?.error ? <Alert severity="error">{shortLinks[shortName].error}</Alert> : (shortLinks[shortName]?.uploaded == false) && <Alert severity="warning">Not updated to the server</Alert>}
                <div style={{
                    display: 'flex', flexDirection: 'colmum', gap: '10px', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    <TextField
                        margin="normal"
                        label="Short Name"
                        id="outlined-disabled"
                        required
                        value={shortName}
                        pattern="[a-zA-Z0-9]+"
                        autoComplete="off"
                    />
                    <TextField
                        margin="normal"
                        label="Referral"
                        variant="outlined"
                        value={referral}
                        onChange={(e) => setReferral(e.target.value)}
                        pattern="[a-zA-Z0-9]+"
                        autoComplete="off"
                    />
                </div>
                <div style={{
                    display: 'flex', flexDirection: 'colmum', gap: '10px', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    {editMode ?
                        <>
                            <TextField
                                margin="normal"
                                label="Link"
                                variant="outlined"
                                required
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                pattern="^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$"
                                autoComplete="off"
                            />  <TextField
                                margin="normal"
                                label="Password"
                                type="password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="off"
                            /> </> :
                        <>
                            <div style={{ width: 'calc(50% - 10px)' }}>
                                <Tooltip title={link}>
                                    <Link href={link} underline="hover" target="_blank">
                                        {'hover for the link'}
                                    </Link>
                                </Tooltip>
                            </div>
                            <div style={{ width: 'calc(50% - 10px)' }}>
                                <Tooltip title={password}>
                                    <Link href="#/admin/short" underline="hover">
                                        {'hover for the password'}
                                    </Link>
                                </Tooltip>
                            </div>
                        </>
                    }

                </div>
                {shortLinks[shortName]?.error ? <>
                    <br />
                    <p>Will be deleted on next update</p>
                </>
                    : (shortLinks[shortName].toBeDeleted == true) ? <>
                        <IconButton onClick={handleRestore}>
                            <RestoreIcon />
                        </IconButton>
                    </> : (editMode ?
                        <>
                            <IconButton onClick={handleSubmit} disabled={disabled}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} disabled={disabled}>
                                <CancelIcon />
                            </IconButton>
                        </>
                        :
                        <>
                            <IconButton onClick={handleCopy}>
                                <FileCopyIcon />
                            </IconButton>
                            {navigator.share &&
                                <IconButton onClick={handleShare}>
                                    <ShareIcon />
                                </IconButton>
                            }
                            <IconButton onClick={handleEdit}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </>)}
            </CardContent>
        </Card>
    );
};

export default function ShortPage() {
    const [uploadData, isUploading, uploadError] = useShortLinkUpdateFirebase();
    const [CreateModal, setCreateModal] = useState(false);

    const handleClose = () => {
        setCreateModal(false);
    };
    const handleUpdate = () => {
        uploadData();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };
    return (
        <main>
            <Helmet>
                <title>Short Links</title>
            </Helmet>
            {uploadError && <Alert severity="error">{uploadError.message}</Alert>}
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 80, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction
                    key="Add"
                    icon={<AddIcon />}
                    tooltipTitle="Add Quote"
                    onClick={() => setCreateModal(true)}
                />
                <SpeedDialAction
                    key="Sync"
                    icon={<SyncIcon />}
                    tooltipTitle="Sync Server"
                    onClick={handleUpdate}
                />
            </SpeedDial>
            <Modal
                open={CreateModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >

                <div style={{ ...style }}>
                    <ShortLinkForm onClose={handleClose} />
                </div>
            </Modal>
            <ShortLinkList />
        </main>
    );
}
